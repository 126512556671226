

























































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'

import Clipboard from 'v-clipboard'

Vue.use(Clipboard)

@Component({})
export default class ImagesUpload extends Vue {
  offerService = new OfferService()

  formState = {
    loading: false
  }

  file: null | File = null

  uploaded = ''

  onFileChange(e) {
    if (e.target.files.length) {
      this.file = e.target.files[0]
    }
  }

  clipboardSuccessHandler() {
    this.$notify({
      type: 'info',
      title: 'O texto foi copiado.',
      group: 'form'
    })
  }

  async onSubmit() {
    this.formState.loading = true
    this.uploaded = await this.offerService.uploadImage(this.file as File)

    this.$notify({
      type: 'success',
      title: 'Concluído',
      text: 'Upload da imagem concluido com sucesso.',
      group: 'form'
    })

    this.file = null

    this.formState.loading = false
  }
}
